/* global angular */
(function () {
    angular.module('googlechart')
        .value('googleChartApiConfig', {
            useGstaticLoader: true,
            gstaticLoaderVersion: '1.1',
            version: '1.1',
            optionalSettings: {
                packages: ['corechart'] //load just the package you want
            }
        });
})();